import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    gray: {
      900: '#1F2023',
      800: '#353639',
      700: '#4c4d4f',
      600: '#626365',
      500: '#79797b',
      400: '#8f9091',
      300: '#a5a6a7',
      200: '#bcbcbd',
      100: '#d2d2d3',
      50: '#F0F3F9',
    },
    navy: {
      900: '#0d0d15',
      800: '#100f19',
      700: '#12121d',
      600: '#151422',
      500: '#171726',
      400: '#31303f',
      300: '#484755',
      200: '#5f5e6a',
      100: '#76757f',
      50: '#8d8c95',
    },
    blue: {
      900: '#324199',
      800: '#3a4cb3',
      700: '#4257cc',
      600: '#4b62e6',
      500: '#536dff',
      400: '#647cff',
      300: '#758aff',
      200: '#8799ff',
      100: '#dde2ff',
      50: '#eef0ff',
    },
    green: {
      900: '#007d3e',
      800: '#009248',
      700: '#00a652',
      600: '#00bb5d',
      500: '#00d067',
      400: '#1ad576',
      300: '#33d985',
      200: '#4dde95',
      100: '#ccf6e1',
      50: '#e6faf0',
    },
    red: {
      900: '#992700',
      800: '#b32e00',
      700: '#cc3400',
      600: '#e63b00',
      500: '#ff4100',
      400: '#ff541a',
      300: '#ff6733',
      200: '#ff7a4d',
      100: '#ffd9cc',
      50: '#ffece6',
    },
    orange: {
      900: '#ff4f00',
      800: '#ff6119',
      700: '#ff7233',
      600: '#ff844d',
      500: '#ff9566',
      400: '#ffa780',
      300: '#ffb999',
      200: '#ffcab3',
      100: '#ffdccc',
      50: '#ffede6',
    },

    black: '#1F2023',
    white: '#f8f9fc',
  },
  fonts: {
    body: 'Inter',
    heading: 'Inter',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  styles: {
    global: ({ colorMode }) => ({
      '*': {
        fontSize: '13px',
        letterSpacing: '0.4px',
        lineHeight: '23px',
      },
      body: {
        color: colorMode === 'dark' ? 'white' : 'gray.700',
      },
    }),
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  components: {
    Drawer: {
      variants: {
        permanent: {
          dialog: {
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
    Modal: {
      sizes: {
        '6xl': {
          dialog: {
            maxW: '1070px',
          },
        },
      },
    },
    Input: {
      variants: {
        outline: ({ colorMode }) => ({
          field: {
            fontSize: '14px',
            borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.100',
            '&::placeholder': {
              color: colorMode === 'dark' ? 'gray.500' : 'gray.500',
            },
          },
        }),
      },
    },
    Select: {
      variants: {
        outline: ({ colorMode }) => ({
          field: {
            fontSize: '14px',
            borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.100',
            '&::placeholder': {
              color: colorMode === 'dark' ? 'gray.500' : 'gray.500',
            },
          },
        }),
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '4px',
        fontWeight: '400',
        // color: 'white !important',
      },
      variants: {
        solid: {
          color: 'white',
        },
      },
    },
    Menu: {
      baseStyle: ({ colorMode }) => ({
        list: {
          borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.50',
        },
        item: {
          '&:hover': {
            background: colorMode === 'dark' ? 'gray.600' : 'gray.50',
          },
          '&:focus': {
            background: colorMode === 'dark' ? 'gray.600' : 'gray.50',
          },
        },
      }),
    },
    Container: {
      variants: {
        pausedNode: {
          // background: 'black',
          background: 'gray.800',
          border: '2px dashed var(--chakra-colors-gray-500)',
          w: '400px',
          filter: "brightness(40%)",
        },
        special: {
          // _hover: {
          //   '& > .add-horizontal-step': {
          //     opacity: '1',
          //     transform: 'translateX(50px)',
          //   },
          // },
          background: 'gray.800',
          w: '400px',
        },
        trigger: {
          background: 'blue.700',
          w: '300px',
          h: 140
        },
        placeholder: {
          paddingTop: '0px !important',
          paddingLeft: '0px !important',
          boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
          paddingRight: '0px !important',
          paddingBottom: '0px !important',
          rounded: 'md',
        },
      },
    },
    Text: {
      variants: {
        addNode: {
          as: 'h4',
          fontSize: '2xl',
          textAlign: 'center',
        },
        envSwitch: {
          as: 'h1',
          fontSize: '5xl',
          textAlign: 'center',
        },
        deploy: {
          as: 'h1',
          fontSize: '4xl',
          textAlign: 'center',
        },
        description: {
          maxWidth: '100%',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'gray.200',
        },
        title: {
          whiteSpace: 'nowrap',
          width: '180px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 'bold',
        },
        'tag.blue': {
          fontSize: 'sm',
          borderRadius: 'sm',
          paddingTop: 1,
          paddingBottom: 1,
          paddingInlineStart: 2,
          paddingInlineEnd: 2,
          lineHeight: 1.2,
          fontWeight: 'md',
          color: 'blue.200',
          background: 'rgba(135, 153, 255, 0.16)',
          alignItems: 'center',
          outline: '2px solid transparent',
          outlineOffset: '2px',
        },
      },
    },
    HStack: {
      variants: {
        addNode: {
          justify: 'space-between',
        },
        envSwitch: {
          justify: 'space-between',
          textAlign: 'center',
          alignItems: 'normal',
        },
        deploy: {
          as: 'h1',
          fontSize: '4xl',
          textAlign: 'center',
        },
      },
    },
  },
});

export default theme;
