export class ActionModel {
  key: string;

  icon: string;

  action: ActionTypes;

  alt: string;

  constructor(key: string, icon: string, action: ActionTypes, alt: string) {
    this.key = key;
    this.icon = icon;
    this.action = action;
    this.alt = alt;
  }
}

export enum ActionTypes {
  DELETE = 'delete',
  EDIT = 'edit',
  PAUSE = 'pause',
  EVENT = 'event',
  SAMPLE = 'sample',
  INPUT = 'input',
  VERSION_CONTROL = 'version-control',
  VIEW = 'view',
  CREATE_VERSION = 'create-version',
  SECURITY = 'security',
  RESPONSE = 'response',
}

export interface IMenuAction {
  actions: ActionModel[];
}
